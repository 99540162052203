import React, { useState, useEffect } from 'react';
import '../App.css';
import { t } from 'i18next';

const DocumentList = (props: any) => {
  const [documents, setDocuments] = useState<any[]>([]); // Inicializa como un array vacío
  const [error, setError] = useState<string | null>(null); // Especifica el tipo de 'error' como string | null

  const fetchDocuments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SHAREDFILE_BASE_URL}${process.env.REACT_APP_SHAREDFILE_PARTIAL_URL_LISTDOCUMENTS}${props.meetingId}`, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error fetching documents: ${response.statusText}`);
      }
      const data = await response.json();
      setDocuments(data.Value); // Establece los documentos obtenidos del servidor
    } catch (error: any) {
      setError(error.message); // Captura y maneja cualquier error que ocurra durante la obtención de documentos
    }
  };

  const deleteDocument = async (fileName: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SHAREDFILE_BASE_URL}${process.env.REACT_APP_SHAREDFILE_PARTIAL_URL_DELETEDOCS}${encodeURIComponent(fileName)}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error deleting document: ${response.statusText}`);
      }
      // Actualiza el estado de documentos eliminando el documento con el nombre de archivo especificado
      setDocuments(documents.filter((doc) => doc.FileName !== fileName));
    } catch (error: any) {
      setError(error.message); // Maneja errores al eliminar documentos
    }
  };

  useEffect(() => {
    fetchDocuments(); 
  }, []); 

  return (

    <>
      {documents.length > 0 && <div className="document-list-container">
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <ul className="document-list">
          <p style={{ color: 'black' }}> {t('documentsList.filesUplaod')}{documents.length}</p>
          {documents && documents.map((doc) => (
            <li key={doc.FileName} className="document-item">
              <span className="document-link">
                ·{(doc.FileName).split('/')[1]}
              </span>
              <button onClick={() => deleteDocument(doc.FileName)} className="delete-button">
                &#x2716;
              </button>
            </li>
          ))}
        </ul>
      </div>}
   </>
  );
};

export default DocumentList;
