import React from "react";
import { Menu } from "semantic-ui-react";
import LogoGeneralitat from "../logo_generalitat_blanc.svg";

import { Trans } from "react-i18next";

export default function Footer({
  inverted = true,
  content = <Trans i18nKey="loading" />,
}) {
  const version = `${process.env.REACT_APP_VERSION_TAG}`;
  return (
    <Menu className="footer" stackable inverted>
      <Menu.Item>
        <img
          alt="Generalitat de Catalunya"
          src={LogoGeneralitat}
          style={{ width: 106 }}
        />
      </Menu.Item>
      <div style={{ alignSelf: "center", marginLeft: 4 }}>
        <small style={{ color: "white" }}>Version {version}</small>
      </div>
    </Menu>
  );
}
